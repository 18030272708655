<template>
    <div class="bg-dark py-4 text-white">
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="fl-x-cc">
                        <img src="../assets/home/Arshina-Logo-Footer-600x367.png"
                             style="filter: grayscale(100%)!important;-webkit-filter: grayscale(100%)!important;"
                             class="img-fluid footer-logo" alt="">
                    </div>
                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <!--                    <div class="fl-x-cc w-100 mt-4 fs-lg-1">-->
                    <!--                        <p class="fs-lg-1">-->
                    <!--                            Arishina Life Sciences Private Limited, Sy.No 216/1 & 216/2, Guruvinpura Road, Devlapura-->
                    <!--                            Junction, Lakkur village, Therkanambi, Chamaraj Nagar, Karnataka,571123-->
                    <!--                        </p>-->
                    <!--                    </div>-->
                    <div class="w-100 mt-4 fs-lg-1">
                        <div class="mb-3 font-lato-bold">Factory Office:</div>
                        <p class="lh-17 fs--1">
                            Arishina Life Sciences Pvt. Ltd.
                            Survey No-216/1 & 216/2
                            Guruvinpura Road
                            Therkanambi, Lakkur Village
                            Gundlupet Taluk, Chamarajnagar Dist
                            Karnataka, India - 571123
                            Mob: +91 82292 95061, 82292 95031
                        </p>
                    </div>
                </div>
                <div class="col-12 mt-4 col-md-6 col-lg-3">
                    <div>
                        <div class="mb-2 font-lato-bold">Registered Office :</div>
                        <p class="lh-17 fs--1">
                            Arishina Life Sciences Pvt. Ltd.
                            Second Floor, 17/560-C8
                            Mini Mall, Athani
                            Nedumbassery, Ernakulam
                            Kerala, India - 683585
                            Mob: +91 86060 30799
                        </p>
                    </div>

                </div>
                <div class="col-12 col-md-6 col-lg-3">
                    <div class="fl-x-cc mt-4 h-100">
                        <div class="fl-x fl-j-sb fl-a-c">
                            <!--                            <img src="../assets/soccial-icons/LinkedIn.png" class=" social-icon" alt="">-->
                            <!--                            <img src="../assets/soccial-icons/Youtube.png" class="ml-3 social-icon" alt="">-->
                            <!--                            <img src="../assets/soccial-icons/Twitter.png" class="ml-3 social-icon" alt="">-->
                            <!--                                            <div class="fl-x fl-j-sb w-20">-->
                            <!--                                                <img src="../assets/soccial-icons/LinkedIn.png" class=" social-icon" alt="">-->
                            <!--                                                <img src="../assets/soccial-icons/Youtube.png" class="ml-3 social-icon" alt="">-->
                            <!--                                                <img src="../assets/soccial-icons/Twitter.png" class="ml-3 social-icon" alt="">-->
                            <!--                                            </div>-->
                            <div class="d-inline social-media">
                                <!--                                <a href="" class="social-icon"><i class="fa fa-facebook"></i></a>-->
                                <!--                                <a href="" class="social-icon"><i class="fa fa-instagram"></i></a>-->
                                <a href="mailto:mail@arishina.in" class="social-icon"><i class="fa fa-envelope"></i></a>
                                <a href="https://www.linkedin.com/company/arishina-life-sciences" target="_blank"
                                   class="social-icon"><i
                                    class="fa fa-linkedin"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-12">
                    <div class="fl-x-cc">
                        <div class="py-3 fl-y-cc text-center">
                            <div class="pt-3 fl-y-cc text-center">
                                <p class="fs-lg-1">These statements have not been evaluated by the Food and Drug
                                    Administration. This
                                    product is not intended to diagnose, treat, cure, or prevent any disease.</p>
                            </div>
                            <!--                            <div class="text-decoration-none mt-3 fl-x fl-j-sa ">-->
                            <!--                                <a href="" class="text-decoration-none px-3 font-weight-bold">Privacy Policy</a>-->
                            <!--                                <a href="" class="text-decoration-none px-3 font-weight-bold">Contact Us</a>-->
                            <!--                                <a href="" class="text-decoration-none px-3 font-weight-bold">Blog & News</a>-->
                            <!--                            </div>-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainFooter'
};
</script>

<style scoped lang="scss">
//.social-icon {
//    height: 2.5rem !important;
//}

.social-icon {
    //height: 2.5rem !important;
    padding: 1rem;
    transition: all 300ms ease-in-out;
    border: 2px solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    width: 40px;
    margin-left: 10px;
    text-decoration: none;

    &:hover {
        //opacity: 0.8;
        background-color: var(--color-secondary);
        color: black;
    }
}

.footer-logo {
    height: 5rem;
    @media(min-width: 992px) {
        height: 8rem;
    }
}

.bg-dark {
    //background-color: #394048;
    background-color: #2f353b;
}
</style>
