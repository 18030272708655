<template>
    <div class="" id="top">
        <div class="nav-container d-lg-none bs-6">
            <lego-nav-bar  drop-down-animation="fade" hover-effect="" drop-down-animation-mobile="fade" :colored="true"
                          :items="menu" shadow="0" class="container-fluid">
                <template #logo>
                    <router-link to="/">
                        <!--                        <img src="../assets/img/logo/logo.png" alt="">-->
                        <img src="../assets/home/Arishina-200x123.jpg" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>
        <div class="nav-lg-green-container d-sm-none bs-6">
            <lego-nav-bar drop-down-animation="fade" hover-effect="" drop-down-animation-mobile="fade" :colored="true"
                          :items="menu" shadow="0" class="container-fluid">
                <template #logo>
                    <router-link to="/">
                        <!--                        <img src="../assets/img/logo/logo.png" alt="">-->
                        <img src="../assets/home/logo.png" alt="">
                    </router-link>
                </template>
            </lego-nav-bar>
        </div>

        <div class="bg-2 c-content-pt">
            <transition name="fade" mode="out-in">
                <router-view>

                </router-view>
            </transition>
        </div>

        <main-footer></main-footer>
    </div>

</template>

<script>
// import LegoNavBar from 'lego-framework/src/components/top-navbar-scroll-to/NavBarScrollTo';
import LegoNavBar from 'lego-framework/src/components/NavBar';
import getMenu from '../data/menu';
import MainFooter from '@components/MainFooter';

export default {
    components: {
        MainFooter,
        LegoNavBar
    },
    data () {
        return {
            menu: getMenu(),
            value: ''
        };
    },
    metaInfo: {
        titleTemplate: '%s - Project Name',
        htmlAttrs: {
            lang: 'en',
            amp: true
        }
    },
    name: 'MainLayout'
};
</script>

<style lang="scss">
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}

.c-content-pt {
    //padding-top:4.5rem;
    padding-top: 3.625rem;
    @media (min-width: 992px) {
        //padding-top: 6.8125rem;
        padding-top: 5.625rem;
    }
}
</style>
