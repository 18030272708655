import store from '../store';

const getMenu = function () {
    const user = store.getters.currentUser;

    const menu = [
        {
            icon: '',
            text: 'Home',
            link: '/'
        },
        {
            icon: '',
            text: 'About Us',
            link: '/about/'
        },
        {
            icon: '',
            text: 'Manufacturing',
            link: '/manufacturers/'
        },
        {
            icon: '',
            text: 'R&D',
            link: '/research/'
        },
        {
            icon: '',
            text: 'Supply Chain',
            link: '/supply-chain/'
        },
        {
            icon: '',
            text: 'Sustainability',
            link: '/sustainability/'
        },
        // {
        //     icon: '',
        //     text: 'Delivery',
        //     link: '#',
        //     items: [
        //         {
        //             text: 'Product 1',
        //             link: '/product-1/'
        //         },
        //         {
        //             text: 'Product 1',
        //             link: '/product-2/'
        //         }
        //     ]
        // },
        // {
        //     icon: '',
        //     text: 'Application',
        //     link: '#',
        //     items: [
        //         {
        //             text: 'Sports',
        //             link: '/sports/'
        //         },
        //         {
        //             text: 'Ageing',
        //             link: '/ageing/'
        //         },
        //         {
        //             text: 'Inflammation',
        //             link: '/inflammation/'
        //         },
        //         {
        //             text: 'Lipids',
        //             link: '/lipids/'
        //         },
        //         {
        //             text: 'Eye Health',
        //             link: '/eye-health/'
        //         },
        //         {
        //             text: 'Blood Sugar',
        //             link: '/blood-sugar/'
        //         }
        //     ]
        // },
        {
            text: 'Product Range',
            link: '#',
            items: [
                {
                    text: 'Human Nutrition',
                    link: '#',
                    items: [
                        {
                            text: 'Standard Extracts',
                            link: '#',
                            items: [
                                {
                                    text: 'Curcumin Extract',
                                    link: '/curcumin-extract/'
                                },
                                {
                                    text: 'CUR-O-STAIN™',
                                    link: '/Cur-o-stain/'
                                },
                                {
                                    text: 'CURO-DIS-95™',
                                    link: '/CURO-DIS-95/'
                                },
                                {
                                    text: 'LUTEIN-EXTRACTS',
                                    link: '/Lutein-Extract/'
                                }
                                // {
                                //     text: 'Other Botanical Extracts',
                                //     link: '/other-botanical-extracts/'
                                // }
                            ]
                        },
                        {
                            text: 'Phyto-Res Technology',
                            link: '/phyto-res-technology/'
                        },
                        {
                            text: 'D-Sperse Technology',
                            link: '/d-sperse-technology/'
                        },
                        {
                            text: 'F-biotic',
                            class: 'text-primary f-biotic-menu',
                            link: '/f-biotic/',
                            items: [
                                {
                                    text: 'What is F-biotic',
                                    link: '/f-biotic/#whatIsFbiotic',
                                    class: 'f-biotic-sub-menu'
                                },
                                {
                                    text: 'Types of Resistant Starch',
                                    link: '/f-biotic/#whatIsFbiotic',
                                    class: 'f-biotic-sub-menu'
                                },
                                {
                                    text: 'Mechanism of Action',
                                    link: '/f-biotic/#MechanismOfAction',
                                    class: 'f-biotic-sub-menu'
                                },
                                {
                                    text: 'Health Benefits',
                                    link: '/f-biotic/#HealthBenefits',
                                    class: 'f-biotic-sub-menu'

                                },
                                {
                                    text: 'Resistant Starch',
                                    link: '/f-biotic/#resistantStarch',
                                    class: 'f-biotic-sub-menu'
                                }
                            ]
                        }
                        // {
                        //     text: 'Resistant Starch',
                        //     link: '/Resistant-Starch/'
                        // },
                        // {
                        //     text: 'Amla Extract',
                        //     link: '/Amla-Extract/'
                        // },
                        // {
                        //     text: 'Green Coffee Bean Extract',
                        //     link: '/Green-Coffee-Bean-Extract/'
                        // },
                        // {
                        //     text: 'Green Tea Extract',
                        //     link: '/Green-Tea-Extract/'
                        // },
                        // {
                        //     text: 'Piperine Extract',
                        //     link: '/Piperine-Extract/'
                        // }
                    ]
                },
                {
                    text: 'Animal Nutrition',
                    link: '#',
                    items: [
                        {
                            text: 'Cur-Liv',
                            link: '/cur-liv-technology-page/',
                            class: 'cur-liv-menu text-primary',
                            items: [
                                {
                                    text: 'For Ruminants',
                                    link: '/animal-nutrition-for-ruminants/',
                                    class: 'cur-liv-sub-menu'
                                },
                                {
                                    text: 'For Poultry',
                                    link: '/animal-nutrition-for-poultry/',
                                    class: 'cur-liv-sub-menu'
                                }
                            ]
                        }
                    ]
                },
                // {
                //     text: 'Spice Extract',
                //     link: '#',
                //     items: [
                //         {
                //             text: 'Green Chilly Extract',
                //             link: '/Green-Chilly-Extract/'
                //         },
                //         {
                //             text: 'Ginger Green Extract',
                //             link: '/Ginger-Green-Extract/'
                //         },
                //         {
                //             text: 'Pepper Green Extract',
                //             link: '/Pepper-Green-Extract/'
                //         },
                //         {
                //             text: 'Cardamom Green Extract',
                //             link: '/Cardamom-Green-Extract/'
                //         }
                //     ]
                // },
                // {
                //     text: 'Essential Oil',
                //     link: '/Naturalessentialoil/'
                //     // items: [
                //     //     {
                //     //         text: 'Natural essential oil',
                //     //         link: '/Naturalessentialoil/'
                //     //     }
                //     // ]
                // },
                {
                    text: 'Other Botanical Extracts',
                    link: '/other-botanical-extract/'
                },
                // {
                //     text: 'Spice and Herb Extracts',
                //     link: '/Spiceandherbextract/'
                // },

                {
                    text: 'Natural Colors',
                    link: '/Naturalcolors/'
                }
            ]
        },

        {
            icon: '',
            text: 'Media',
            link: '#',
            items: [
                {
                    text: 'Blogs',
                    link: '/media/'
                },
                {
                    text: 'Downloads',
                    link: '/downloads/'
                }
            ]
        },
        {
            icon: '',
            text: 'Contact',
            link: '/contact/'
        }

    ]
    ;

    const adminMenu = [
        {
            text: 'Admin Master Data',
            link: '#',
            items: [
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                },
                {
                    text: 'Master Data',
                    link: '/app/master-data/'
                }
            ]
        }
    ];

    // Do something similar for any number of roles
    if (user && user.admin === true) {
        return [
            ...menu,
            ...adminMenu
        ];
    }
    return [
        ...menu
    ];
};
export default getMenu;
